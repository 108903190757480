import styled from 'styled-components/macro'
import { AlertTriangle, Info } from 'react-feather'
import { Trans } from '@lingui/macro'
import { ThemedText } from 'theme'
import { ExternalLink } from 'theme/components'

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`

const BannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.accentWarningSoft};
  border-radius: 12px;
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
`

const IconWrapper = styled.div`
  color: ${({ theme }) => theme.accentWarning};
  margin-right: 12px;
  margin-top: 2px;
`

const StyledLink = styled(ExternalLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.textPrimary};
  
  &:hover {
    color: ${({ theme }) => theme.textSecondary};
  }
`

const ImportantBanner = styled(BannerWrapper)`
  border: 1px solid ${({ theme }) => theme.accentWarning};
`

const NoteBanner = styled(BannerWrapper)`
  background-color: ${({ theme }) => theme.accentActionSoft};
  border: 1px solid ${({ theme }) => theme.accentAction};
`

const ImportantIconWrapper = styled(IconWrapper)`
  color: ${({ theme }) => theme.accentWarning};
`

const NoteIconWrapper = styled(IconWrapper)`
  color: ${({ theme }) => theme.accentAction};
`

const ImportantText = styled(ThemedText.BodySmall)`
  font-weight: 600;
`

const NoteText = styled(ThemedText.BodySmall)`
  opacity: 0.8;
`

const StakingDeprecationBanner = () => {
  return (
    <BannerContainer>
      <ImportantBanner>
        <ImportantIconWrapper>
          <AlertTriangle size={20} />
        </ImportantIconWrapper>
        <ImportantText>
          <Trans>
            Important: The Fee Staking service has already ended, and the Staking page will be closed at the end of May 2025. Please make sure to{' '}
            <StyledLink href="https://v2.arthswap.org/#/staking">
              unstake your tokens
            </StyledLink>{' '}
            before May 31st, 2025 (UTC).
          </Trans>
        </ImportantText>
      </ImportantBanner>
      
      <NoteBanner>
        <NoteIconWrapper>
          <Info size={20} />
        </NoteIconWrapper>
        <NoteText>
          <Trans>
            Note: This only affects Fee Staking - regular liquidity provision remains unaffected and will continue as normal.
          </Trans>
        </NoteText>
      </NoteBanner>
    </BannerContainer>
  )
}

export default StakingDeprecationBanner 